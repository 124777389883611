import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import contact from "./contact.module.css";
import {
  useGetContactQuery,
  useGetDocumentsQuery,
} from "../culture/companyApiSlice";
import { Link } from "react-router-dom";
import Footer from "../../layout/Footer";

function Contact(props) {
  const { data, isLoading: contactLoading } = useGetContactQuery();
  const { data: documents, isLoading: docsLoading } = useGetDocumentsQuery();

  const brochure = documents?.documents?.find((doc) => doc.type === "brochure");

  const contactInfo = data?.contact;
  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <body className={contact.body} onClick={() => props.setMenuOpen(false)}>
      <main
        className={contact.main}
        data-aos="fade-in"
        data-aos-offset="0"
        data-aos-easing="ease-in-sine"
      >
        <section className={contact.mainContainer}>
          <div className={contact.contentBox}>
            <h1>START THE CONVERSATION</h1>

            <div className={contact.items}>
              <div className={contact.item}>
                  <h6>CALL</h6>
                  <ul>
                    {
                      contactInfo?.map((contact)=>
                        contact.type === 'phone' && <li>{contact.value}</li>
                      )
                    }
                  </ul>
                </div>
                <div className={contact.item}>
                  <h6>EMAIL</h6>
                  <ul>
                    {contactInfo?.map((contact)=>
                        contact.type === 'email' && <li>{contact.value}</li>
                      )}
                  </ul>
                </div>
                <div className={contact.item}>
                  <h6>LOCATION</h6>
                  <ul>
                    {
                      contactInfo?.filter((contact)=>contact.type==='address').map((contact, index)=>
                        <>
                        <li>{contact.value}</li>
                        <div className={contact.item}></div>
                        <div className={contact.item} style = {{margin:'1rem 0rem'}}>
                        <a href={`${contactInfo?.filter((contact)=>contact?.type==='direction')[index]?.value}`} target = '_blank'>GET DIRECTIONS</a>
                        </div>
                        <br/>
                        </>
                      )
                    }
                  </ul>
                </div>
                <div className={contact.brochure}>
                  <a href={brochure?.url || ""} download>
                    Download our company brochure
                  </a>
                </div>

            </div>

          </div>
        </section>
      </main>

      <Footer style={contact}/>
    </body>
  );
}

export default Contact;
