import React, { useEffect } from "react";
import primeTech1 from "../../images/PrimeTech_01.jpg";
import business from "./business.module.css";
import businessImg from "../../images/business-portfolio-images/businessImg.jpg";
import Aos from "aos";
import "../home/carousel/flickity.css";
import "aos/dist/aos.css";
import { useGetPortfolioQuery } from "./portfolioApiSlice";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../segments/slick.css";
import { Link, useLocation } from "react-router-dom";
import ImageKit from "../../helpers/ImageKit";
import { Box, CircularProgress } from "@mui/material";
import Footer from "../../layout/Footer";

function BusinessPortfolio(props) {
  const { data, isLoading: portfolioLoading } = useGetPortfolioQuery();
  const portfolio = data?.portfolios;
  const location = useLocation();
  const deptId = location?.state?.id; // to scroll to if coming from search

  const metaText = data?.meta?.blocks?.find(
    (block) => block?.type === "text"
  );

  const metaImage = data?.meta?.blocks?.find(
    (block) => block?.type === "image"
  );

  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  useEffect(() => {
    // scroll to content if coming from search page
    if (deptId) {
      document?.getElementById(deptId)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [deptId, data]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <body className={business.body} onClick={() => props.setMenuOpen(false)}>
      <section className={business.sectionOne}>
        <div className={business.container}>
          <div className={business["main-header"]}>
            <h1>BUSINESS PORTFOLIO</h1>
          </div>
        </div>
        <div className={business["front-cover"]}>
          <div className={business["container-2"]}>
            <img src={
                        ImageKit({url:metaImage?.media?.[0]?.url}) || ""
                      } alt="" className={business["image-size"]} />
          </div>
        </div>
        <div className={business.container}>
          <h3>
          {parse(metaText?.content || "")}
          </h3>
        </div>
      </section>

      <section className={business.section}>
        <div className={business.container}>
          {portfolioLoading ? (
            <div className={business.loading}>
              <CircularProgress sx={{ color: "rgba(0,0,0,0.4)" }} />
            </div>
          ) : (
            <div id={business["section-nav"]}>
              <ul>
                {portfolio?.map((dept, i) => (
                  <li key={i}>
                    <a href={`#${dept?.id}`}>{dept?.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
      {/* BUILDINGS */}
      {portfolio ? (
        <section className={`${business.second} ${business.section}`}>
          {portfolio?.map((dept, i) => (
            <div
              key={i}
              id={`${dept?.id}`}
              className={`${business.container} ${business.portfolio}`}
            >
              <div className={`${business.blockContainer}`}>
                <div className={business.category}>
                  <p>0{i + 1}</p>
                </div>

                <div className={business.block}>
                  <div
                    className={business["item-one"]}
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                  >
                    <h2>{dept?.name}</h2>
                  </div>
                  <div
                    className={business["item-two"]}
                    data-aos="fade-in"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                  >
                    {dept?.blocks
                      ?.filter((block) => block?.type === "text")
                      ?.map((block, i) => (
                        <p className={business.textBlock} key={i}>
                          {parse(block?.content)}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
              <Box
                sx={{
                  "& .slick-next": {
                    right: { xs: 0, md: "-25px" },
                  },
                }}
              >
                <Slider {...settings}>
                  {dept?.blocks
                    ?.find((block) => block?.type === "image")
                    ?.media?.map((image, i) => (
                      <div key={i} className={business["block-image"]}>
                        <img
                          src={ImageKit({url:image?.url}) || ""}
                          alt={dept?.name}
                        />
                      </div>
                    ))}
                </Slider>
              </Box>
            </div>
          ))}
        </section>
      ) : (
        ""
      )}

      <section
        className={`${business.view} ${business.section}`}
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
      >
        <div className={business.container}>
          <Link to="/segments">
            <h3>
              VIEW OUR <br /> SERVICES
            </h3>
          </Link>
          <Link to="/references">
            <h3>
              VIEW OUR <br /> FEATURED PROJECTS
            </h3>
          </Link>
        </div>
      </section>

      <Footer style={business}/>
    </body>
  );
}

export default BusinessPortfolio;
