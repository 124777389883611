import React from "react";
import AnimatedCursor from "react-animated-cursor";

function AnimCursor(menuToggle, itemTwo) {
  return (
    <>
      <AnimatedCursor
        innerSize={10}
        outerSize={15}
        color={
          menuToggle?.menuToggle === true ? `255, 255, 255` : `140, 74, 55`
        }
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
          "#menu-icon",
        ]}
      />
    </>
  );
}

export default AnimCursor;
