import Aos from "aos";
import React, { useEffect } from "react";
import job from "./job.module.css";
import "aos/dist/aos.css";
import { Link, useLocation } from "react-router-dom";
import { useGetSingleCareerQuery } from "./careerApiSlice";
import parse from "html-react-parser";
import { CircularProgress } from "@mui/material";
import Footer from "../../layout/Footer";

function JobListing(props) {
  const location = useLocation();
  const careerId = location?.state?.data;

  const { data: career, isLoading: careerLoading } =
    useGetSingleCareerQuery(careerId);

  const jobData = career?.career;
  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <body className={job.body} onClick={() => props.setMenuOpen(false)}>
      <main className={job.main}>
        <section className={job.container}>
          <div className={job["main-header"]}>
            <button>APPLY</button>
            <h1>{jobData?.position || ""}</h1>
          </div>

          <div className={job.segment}>
            <h3>{careerLoading ? "" : "SEGMENT"}</h3>
            <p>{jobData?.segment ? jobData?.segment?.name : ""}</p>
          </div>
          {careerLoading ? (
            <div className={job.loading}>
              {" "}
              <CircularProgress sx={{ color: "lightgray" }} />
            </div>
          ) : (
            <div className={job.text}>
              {jobData ? parse(`${jobData?.description}`) : ""}
            </div>
          )}
        </section>
      </main>

      <Footer style={job}/>
    </body>
  );
}

export default JobListing;
