import React, { useEffect } from "react";
import placeholderImg from "../../images/placeholder_img.png";
import { Link } from "react-router-dom";
import about from "./about.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useGetTeamQuery } from "../culture/companyApiSlice";
import { Avatar, CircularProgress } from "@mui/material";
import { useGetAboutQuery } from "../culture/companyApiSlice";
import { useGetSegmentsQuery } from "../segments/segmentsApiSlice";
import parse from "html-react-parser";
import ImageKit from "../../helpers/ImageKit";
import Footer from "../../layout/Footer";

function About(props) {
  const { data: abt, isLoading } = useGetAboutQuery();
  const { data, isLoading: teamLoading } = useGetTeamQuery();
  const { data: segments, isLoading: segmentsLoading } = useGetSegmentsQuery();

  const metaText = abt?.meta?.blocks?.find((block) => block?.type === "text");

  const metaImage = abt?.meta?.blocks?.find((block) => block?.type === "image");

  const imageBlock = abt?.about?.blocks?.find(
    (block) => block?.type === "image"
  );

  const textBlocks = abt?.about?.blocks?.filter(
    (block) => block?.type === "text"
  );

  const firstTwo = textBlocks?.slice(0, 2);

  const team = data?.team;
  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  return (
    <body className={about.body} onClick={() => props.setMenuOpen(false)}>
      <main className={about.main}>
        <section className={about.firstSection}>
          <div className={about["front-cover"]}>
            <div className={about["container-2"]}>
              <img
                src={
                  ImageKit({url:metaImage?.media?.[0]?.url}) || ""
                }
                alt=""
                className={about["image-size"]}
              />
            </div>
          </div>
          <div className={about.container}>
            <div className={about["main-header"]}>
              <h3>{parse(metaText?.content || "")}</h3>
            </div>
          </div>
        </section>
        <section className={about.section}>
          {abt ? (
            <div className={about["container-2"]}>
              <div className={about.container}>
                <div className={about.items}>
                  <div
                    className={about["item-one"]}
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-easing="ease-in-sine"
                  >
                    {firstTwo?.map((block, i) => (
                      <p key={i}>{parse(block?.content) || ""}</p>
                    ))}
                  </div>
                  <div
                    className={about["item-two"]}
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                  >
                    <img
                      src={
                        ImageKit({url:imageBlock?.media?.[0]?.url}) || placeholderImg
                      }
                      alt=""
                      className={about["image-size"]}
                    />
                  </div>
                </div>
                <p>{parse(textBlocks?.[2]?.content || "")}</p>
              </div>
            </div>
          ) : (
            <div className={about.loading}>
              {" "}
              <CircularProgress sx={{ color: "lightgray" }} />
            </div>
          )}
        </section>

        <section
          id="our-people"
          className={`${about["our_people"]} ${about.section}`}
        >
          <div
            className={about.container}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
          >
            <h2>OUR PEOPLE</h2>
            <h6>A collective of perspectives, motivations and talents.</h6>
            <div className={about.people}>
              {team?.map((profile, i) => (
                <div
                  key={i}
                  className={about.item}
                  data-aos="fade-up"
                  data-aos-offset="50"
                  data-aos-easing="ease-in-sine"
                >
                  <Link
                    to="people"
                    className={about.link}
                    state={{ id: profile.id }}
                  >
                    <div
                      className={about.profileImage}
                      style={{
                        backgroundImage: `url(${
                          ImageKit({
                            url: profile?.image?.url,
                            hieght: 500,
                            grayscale: true,
                          }) || <Avatar />
                        })`,
                      }}
                    ></div>
                    <p className={about.name}>{profile?.name}</p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className={about.section}>
          <div
            className={about.container}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
          >
            <h1>SEGMENTS</h1>
            <div className={about.segItems}>
              {segments?.segments?.map((segment, i) => (
                <div key={i} className={about.segItem}>
                  <p>0{i + 1}</p>
                  <h2>{segment?.name}</h2>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>

      <Footer style={about} />
    </body>
  );
}

export default About;
