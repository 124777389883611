import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["News"],
});

const newsPageApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: (limit=null) => limit?`/news?limit=${limit}`:`/news`,
      providesTags: ["News"],
    }),
    getNewsPost: builder.query({
      query: (parameter) => `/news/${parameter}`,
      providesTags: ["News"],
    }),
    getNewsCategories: builder.query({
      query: () => `/news/categories`,
      providesTags: ["News"],
    }),
    getCategory: builder.query({
      query: (parameter) => `/news/category/${parameter}`,
      providesTags: ["News"],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetNewsCategoriesQuery,
  useGetCategoryQuery,
  useGetNewsPostQuery,
} = newsPageApiSlice;
