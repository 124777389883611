import React, { useEffect, useState } from "react";
import ref from "./reference.module.css";
import { useGetPortfolioQuery } from "../business-portfolio/portfolioApiSlice";
import { useGetReferencesQuery } from "./referencesApiSlice";
import Aos from "aos";
import "aos/dist/aos.css";
import placeholderImg from "../../images/placeholder_img.png";
import RefCategory from "./RefCategory";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import ImageKit from "../../helpers/ImageKit";
import Footer from "../../layout/Footer";

function References(props) {
  const { data, isLoading: portfolioLoading } = useGetPortfolioQuery();

  const { data: references, isLoading: referenceLoading } =
    useGetReferencesQuery();

  const [activeTab, setActiveTab] = useState("All");

  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <body className={ref.body} onClick={() => props.setMenuOpen(false)}>
      <div className={ref["mini-navbar"]}>
        <div className={ref.container}>
          <ul>
            <li>
              <a
                onClick={() => setActiveTab("All")}
                className={activeTab === "All" ? ref.active : ""}
              >
                ALL
              </a>
            </li>
            {data?.portfolios?.map((portfolio, i) => (
              <li key={i}>
                <a
                  onClick={() => setActiveTab(`${portfolio?.name}`)}
                  className={
                    activeTab === `${portfolio?.name}` ? ref.active : ""
                  }
                >
                  {portfolio?.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <main className={ref.main}>
        <div className={ref.container}>
          <div className={ref["main-header"]}>
            <h1>REFERENCES</h1>
          </div>
        </div>

        <div className={ref.container}>
          {referenceLoading ? (
            <div className={ref.noNews}>
              <CircularProgress sx={{ color: "lightgray" }} />
            </div>
          ) : data?.portfolios?.find(
              (portfolio) => portfolio?.name === activeTab
            ) ? (
            <RefCategory
              className={ref.cat}
              category={(() => {
                const selectedPortfolio = data?.portfolios?.find(
                  (portfolio) => portfolio?.name === activeTab
                );
                return selectedPortfolio;
              })()}
            />
          ) : (
            <div className={ref.items}>
              {references?.references?.map((refItem, i) => (
                <div
                  key={i}
                  className={ref.item}
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                >
                  <Link
                    to={`reference/${refItem.id}`}
                    state={{ id: refItem?.id }}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className={ref.refBox}
                      style={{
                        backgroundImage: ` url(${
                          ImageKit({url:refItem?.featured_image}) || placeholderImg
                        })`,
                      }}
                    >
                      <span>
                        <p>{refItem?.title || "Title"}</p>
                      </span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </main>

      <Footer style={ref} />
    </body>
  );
}

export default References;
