import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Portfolio"],
});

const portfolioApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPortfolio: builder.query({
      query: () => `/portfolio`,
      providesTags: ["Portfolio"],
    }),
  }),
});

export const { useGetPortfolioQuery } = portfolioApiSlice;
