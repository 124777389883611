import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from "./components/layout/Nav";
import Home from "./components/pages/home/Home";
import About from "./components/pages/about/About";
import BusinessPortfolio from "./components/pages/business-portfolio/BusinessPortfolio";
import Segments from "./components/pages/segments/Segments";
import News from "./components/pages/news/News";
import Careers from "./components/pages/careers/Careers";
import Contact from "./components/pages/contact-us/Contact";
import JobListing from "./components/pages/careers/JobListing";
import NewsPost from "./components/pages/news/news-post/NewsPost";
import People from "./components/pages/about/our-people/People";
import Culture from "./components/pages/culture/Culture";
import ScrollToTop from "./components/helpers/ScrollToTop";
import References from "./components/pages/references/References";
import Ref from "./components/pages/references/Ref";
import Search from "./components/pages/search/Search";
import Page from "./components/pages/page/Page";

function App() {
  const [menuOpen, setMenuOpen] = useState(true); // close nav menu when outside of the menu is clicked
  const [whiteIcons, setWhiteIcons] = useState(false); //handle home page icon transition
  const [darkHeader, setDarkHeader] = useState(false); // handle homepage header transition

  return (
    <div className="App">
      <Router>
        {/* <Cursor /> */}
        {/* <AnimCursor /> */}

        <ScrollToTop />
        <Nav whiteIcons={whiteIcons} darkHeader={darkHeader} />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                setWhiteIcons={setWhiteIcons}
                setDarkHeader={setDarkHeader}
              />
            }
          />
          <Route
            path="corporate-culture"
            element={<Culture setMenuOpen={setMenuOpen} />}
          />
          <Route path="about" element={<About setMenuOpen={setMenuOpen} />} />
          <Route
            path="about/people"
            element={<People setMenuOpen={setMenuOpen} />}
          />
          <Route
            path="business-portfolio"
            element={<BusinessPortfolio setMenuOpen={setMenuOpen} />}
          />
          <Route
            path="references"
            element={<References setMenuOpen={setMenuOpen} />}
          />
          <Route
            path="references/reference/:id"
            element={<Ref setMenuOpen={setMenuOpen} />}
          />
          <Route
            path="segments"
            element={<Segments setMenuOpen={setMenuOpen} />}
          />
          <Route path="news" element={<News setMenuOpen={setMenuOpen} />} />
          <Route
            path="news/news-post/:id"
            element={<NewsPost setMenuOpen={setMenuOpen} />}
          />
          <Route
            path="page/:pageSlug"
            element={<Page setMenuOpen={setMenuOpen} />}
          />
          <Route
            path="careers"
            element={<Careers setMenuOpen={setMenuOpen} />}
          />
          <Route
            path="careers/job-listings"
            element={<JobListing setMenuOpen={setMenuOpen} />}
          />
          <Route
            path="contact-us"
            element={<Contact setMenuOpen={setMenuOpen} />}
          />
          <Route path="search" element={<Search setMenuOpen={setMenuOpen} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
