// create and export a function that will take a file url and return an imagekit version
const ImageKit = ({ url, thumbnail, grayscale, blur, smartCrop, hieght, width }) => {
  if (!url) return null;
  const ID = process.env.REACT_APP_IMAGE_KIT_ID;
  const FOLDER = process.env.REACT_APP_SPACE_FOLDER;
  const ID_WITH_FOLDER = `${ID}/${FOLDER ? `${FOLDER}/` : ""}`;
  const imageKitUrl = `https://ik.imagekit.io/${ID_WITH_FOLDER}`;
  const fileName = url.split("/").pop();
  const imageKitUrlWithFile = `${imageKitUrl}${fileName}`;
  const imageKitUrlWithFileAndOptions = `${imageKitUrlWithFile}?tr=${thumbnail ? "h-300,w-300," : ""}${grayscale ? "e-grayscale," : ""}${blur ? "b-10," : ""}${smartCrop ? "fo-auto," : ""}${hieght ? `h-${hieght},` : ""}${width ? `w-${width},` : ""}`;
  return imageKitUrlWithFileAndOptions;
};

export default ImageKit;
