import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["page"],
});

const pageApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPages: builder.query({
      query: () => `/pages`,
      providesTags: ["page"],
    }),
    getPage: builder.query({
      query: (parameter) => `/page/${parameter}`,
      providesTags: ["page"],
    }),
  }),
});

export const {
  useGetPageQuery,
  useGetPagesQuery,
} = pageApiSlice;
