import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import placeholderImg from "../../../images/placeholder_img.png";
import owl from "./owl.module.css";
import "./owlStylesTwo.css";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useGetPortfolioQuery } from "../../business-portfolio/portfolioApiSlice";
import { CircularProgress } from "@mui/material";
import ImageKit from "../../../helpers/ImageKit";
import LazyLoad from "react-lazyload";

function CarouselTwo(props) {
  const { data, isLoading } = useGetPortfolioQuery();

  const portfolios = data?.portfolios;
  const screenSize = window.innerWidth;

  return (
    <>
      {isLoading ? (
        <div className={owl.loadingDiv}>
          {" "}
          <CircularProgress />{" "}
        </div>
      ) : (
        <OwlCarousel
          className="owl-theme"
          nav={true}
          navText={[
            screenSize < 800 ? "<" : "PREV",
            screenSize < 800 ? ">" : "NEXT",
          ]}
          items={screenSize < 800 ? 1 : 3}
          margin={30}
          dots={false}
        >
          {portfolios?.map((portfolio) => (
            <LazyLoad>
              <div key={portfolio.id} className={owl.item}>
                <div
                  className={owl.owlImage}
                  data-aos="fade-in"
                  data-aos-duration={800 * portfolio.id}
                  style={{
                    backgroundImage: `radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), url(${
                      ImageKit({url:portfolio?.blocks?.find(
                        (block) => block?.type === "image"
                      )?.media[0].url}) || placeholderImg
                    })`,
                  }}
                >
                  {portfolio?.name}
                </div>
                <Link to="business-portfolio">{props.btn}</Link>
              </div>
            </LazyLoad>
          ))}
        </OwlCarousel>
      )}
    </>
  );
}

export default CarouselTwo;
