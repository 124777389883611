import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import search from "../Icons/search.svg";
import linkedinIcon from "../Icons/linkedin.svg";
import menuIcon from "../Icons/menu_icon.png";
import logo from "../Icons/logo.png";
import darkLogo from "../Icons/whiteLogo.png";
import Menuline from "../Icons/Line.png";
import { GoSearch } from "react-icons/go";
import { FaEquals } from "react-icons/fa";
import { HiOutlineMenuAlt4, HiOutlineMinus, HiSearch } from "react-icons/hi";
import { ImLinkedin2, ImTwitter, ImInstagram } from "react-icons/im";
import { default as Cursor } from "../customComponents/cursor/Cursor";
import nav from "./nav.module.css";
import AnimCursor from "../customComponents/cursor/animated-cursor/AnimCursor";
import { Stack } from "@mui/system";
import { useGetContactQuery } from "../pages/culture/companyApiSlice";
import { Helmet } from "react-helmet";
import { MdArrowLeft } from "react-icons/md";
import { MdArrowRight, MdOutlineArrowBackIosNew } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";

function Nav(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false); //dark mode
  const [iconWhite, setIconWhite] = useState(false); //nav icons' color
  const [iconBlack, setIconBlack] = useState(false);
  const [active, setActive] = useState(false); // hamburger
  const [display, setDisplay] = useState(false); // cursor color
  const [open, setOpen] = useState(false); // Nav Menu open?
  const [abtMenu, setAbtMenu] = useState(false);

  const { data, isLoading } = useGetContactQuery();
  const contactInfo = data?.contact;

  const currentPage = location.pathname;

  const handleAbtClick = () => {
    setAbtMenu(!abtMenu);
  };

  // useEffect(() => {
  //   if (
  //     props.isMenuOpen === false &&
  //     (location?.pathname === "/references" ||
  //       location?.pathname === "/references/reference")
  //   ) {
  //     setOpen(false);
  //     setDisplay(true);
  //     props.setMenuOpen(true);
  //   } else if (
  //     props.isMenuOpen === false &&
  //     (location?.pathname !== "/references" ||
  //       location?.pathname !== "/references/reference")
  //   ) {
  //     setOpen(false);
  //     setDisplay(false);
  //     props.setMenuOpen(true);
  //   } else {
  //     props.setMenuOpen(true);
  //   }
  // }, [props.isMenuOpen]);

  useEffect(() => {
    if (currentPage === "/") {
      HandleHomePage();
    } else {
    }
  }, [props.whiteIcons, props.darkHeader]);

  // CHANGE HEADER & ICON COLOR  ON DARK PAGES
  useEffect(() => {
    setOpen(false);

    switch (location?.pathname) {
      case "/references":
        HandleReferences();
        //   window.removeEventListener("scroll", navColor);
        break;
        // case "/references/reference":
        //   HandleReferences();
        //  window.removeEventListener("scroll", navColor);
        break;
      case "/about":
        HandleGreyPages();
        //  window.removeEventListener("scroll", navColor);
        break;
      case "/about/people":
        HandleGreyPages();
        //  window.removeEventListener("scroll", navColor);
        break;
      case "/page/sitemap":
        HandleGreyPages();
        //   window.removeEventListener("scroll", navColor);
        break;
      case "/news":
        HandleGreyPages();
        //   window.removeEventListener("scroll", navColor);
        break;
      case "/news/news-post":
        HandleGreyPages();
        //   window.removeEventListener("scroll", navColor);
        break;
      case "/contact-us":
        HandleGreyPages();
        //  window.removeEventListener("scroll", navColor);
        break;
      // case "/":
      //   HandleHomePage();
      //   break;
      default:
        HandleOtherPages();
    }
  }, [location.pathname]);

  // FUNCTIONS TO TOGGLE NAV AND HEADER COLORS BASED ON PAGE

  function HandleHomePage() {
    if (props?.whiteIcons === true && props?.darkHeader === true) {
      setDarkMode(true);
      setIconWhite(true);
    } else if (props?.whiteIcons === true && props?.darkHeader === false) {
      setDarkMode(false);
      setIconWhite(true);
    } else if (props?.whiteIcons === false && props?.darkHeader === true) {
      setDarkMode(true);
      setIconWhite(false);
    } else {
      setDarkMode(false);
      setIconWhite(false);
    }
  }

  function HandleOtherPages() {
    if (location?.pathname.includes("references/reference")) {
      HandleReferences();
    } else {
      setDarkMode(false);
      setIconBlack(false);
      setIconWhite(false);
      setDisplay(false);
    }
  }

  function HandleReferences() {
    setDarkMode(true);
    setIconBlack(false);
    setIconWhite(true);
    setDisplay(true);
  }

  function HandleGreyPages() {
    setDarkMode(false);
    setIconBlack(true);
    setIconWhite(false);
    setDisplay(false);
  }

  const handleMenuClick = () => {
    setOpen(!open);
    setAbtMenu(false);
    if (open === false) {
      setDisplay(true);
      setIconWhite(true);
      setIconBlack(false);
    } else if (open === true) {
      switch (location?.pathname) {
        case "/":
          HandleHomePage();
          setDisplay(false);
          break;
        case "/references":
          HandleReferences();
          break;
        // case `references/reference`:
        //   HandleReferences();
        //   break;
        case "/about":
          HandleGreyPages();
          break;
        case "/about/people":
          HandleGreyPages();
          break;
        case "/news":
          HandleGreyPages();
          break;
        case "/news/news-post":
          HandleGreyPages();
          break;
        case "/contact-us":
          HandleGreyPages();
          break;
        default:
          HandleOtherPages();
      }

      // HandleOtherPagesMenu();
    }
  };

  // function navColor() {
  //   let header = document.querySelector("header");
  //   let scrollValue = (window.scrollY / window.innerHeight) * 100;
  //   // console.log("checking scroll", scrollValue);

  //   if (
  //     location?.pathname === "/" &&
  //     scrollValue >= 112 &&
  //     scrollValue <= 466
  //   ) {
  //     setDarkMode(false);
  //     setIconBlack(true);
  //     setIconWhite(false);
  //   } else if (
  //     location?.pathname === "/" &&
  //     scrollValue >= 467 &&
  //     scrollValue <= 580
  //   ) {
  //     setDarkMode(true);
  //     setIconBlack(false);
  //     setIconWhite(true);
  //   } else if (location?.pathname === "/" && scrollValue >= 734) {
  //     setDarkMode(true);
  //     setIconBlack(false);
  //     setIconWhite(true);
  //   } else {
  //     setDarkMode(false);
  //     setIconBlack(false);
  //     setIconWhite(false);
  //   }
  // }

  // if (currentPage === "/") {
  //   window.addEventListener("scroll", navColor);
  // } else {
  //   window.removeEventListener("scroll", navColor);
  // }

  return (
    <>
      {/* <Cursor /> */}
      <AnimCursor menuToggle={display} />
      <nav className={nav.navbar} id="navbar">
        <div className={nav.wrapper}>
          <div
            id="menu-display"
            className={
              open === true ? `${nav.menu_display} ${nav.menuOpen}` : {}
            }
            // style={{ width: "0%" }}
          >
            <div className={open === true ? nav.container : nav.noDisplay}>
              <ul id="empty" className={nav.empty}>
                <li></li>
              </ul>
              <ul>
                <li>
                  <Link
                    to="/"
                    className={
                      currentPage === "/" ? nav.currentPage : nav.navLink
                    }
                    onClick={HandleOtherPages}
                  >
                    HOME
                  </Link>
                </li>
                <li>
                  <Link
                    to="segments"
                    className={
                      currentPage === "/segments"
                        ? nav.currentPage
                        : nav.navLink
                    }
                    onClick={HandleOtherPages}
                  >
                    SEGMENTS
                  </Link>
                </li>
                <li>
                  <Link
                    to="business-portfolio"
                    className={
                      currentPage === "/business-portfolio"
                        ? nav.currentPage
                        : nav.navLink
                    }
                    onClick={HandleOtherPages}
                  >
                    BUSINESS PORTFOLIO
                  </Link>
                </li>
                <li>
                  <Link
                    to="references"
                    className={
                      currentPage === "/references" ||
                      currentPage === "/references/reference"
                        ? nav.currentPage
                        : nav.navLink
                    }
                    onClick={HandleReferences}
                  >
                    REFERENCES
                  </Link>
                </li>
                <li>
                  <Link
                    to="news"
                    className={
                      currentPage === "/news" ||
                      currentPage === "/news/news-post"
                        ? nav.currentPage
                        : nav.navLink
                    }
                    onClick={HandleGreyPages}
                  >
                    NEWS
                  </Link>
                </li>
                <li>
                  <Link
                    to="careers"
                    className={
                      currentPage === "/careers" ||
                      currentPage === "/careers/job-listings"
                        ? nav.currentPage
                        : nav.navLink
                    }
                    onClick={HandleOtherPages}
                  >
                    CAREERS
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      currentPage === "/about" ||
                      currentPage === "/about/people"
                        ? `${nav.currentPage} ${nav.aboutLink}`
                        : `${nav.navLink} ${nav.aboutLink}`
                    }
                    onClick={handleAbtClick}
                  >
                    <p>ABOUT</p>
                    <IoIosArrowBack
                      className={
                        abtMenu === true ? nav.abtIconOpen : nav.abtIcon
                      }
                    />
                  </Link>
                  <div className={nav.dropdown}>
                    <span
                      className={abtMenu === true ? nav.span : nav.noDisplay}
                    >
                      <Link
                        to="about"
                        className={
                          currentPage === "/about" ||
                          currentPage === "/about/people"
                            ? `${nav.currentPage} ${nav.aboutLink}`
                            : `${nav.navLink} ${nav.aboutLink}`
                        }
                        onClick={handleAbtClick}
                      >
                        - ABOUT US
                      </Link>
                    </span>
                    <span
                      className={abtMenu === true ? nav.span : nav.noDisplay}
                    >
                      <Link
                        to="corporate-culture"
                        className={
                          currentPage === "/corporate-culture"
                            ? `${nav.currentPage} ${nav.aboutLink}`
                            : `${nav.navLink} ${nav.aboutLink}`
                        }
                        onClick={handleAbtClick}
                      >
                        - CORPORATE CULTURE
                      </Link>
                    </span>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <Link
                    to="contact-us"
                    className={
                      currentPage === "/contact-us"
                        ? nav.currentPage
                        : nav.navLink
                    }
                    onClick={HandleGreyPages}
                  >
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={nav.nav}>
          <div className={nav.empty}></div>
          <div className={nav.menu}>
            {/* <img
              src={open === true ? Menuline : menuIcon}
              className={open === true ? nav.menuLine : nav.menuIcon}
              alt=""
              id="menu-icon"
            /> */}
            <center>
              {open === true ? (
                <HiOutlineMinus
                  onClick={handleMenuClick}
                  className={nav.menuOpenIcon}
                  id="menu-icon"
                />
              ) : (
                <HiOutlineMenuAlt4
                  onClick={handleMenuClick}
                  className={
                    iconBlack === true
                      ? nav.menuIconBlack
                      : iconWhite === true
                      ? nav.menuIconWhite
                      : nav.menuIcon
                  }
                  id="menu-icon"
                />
              )}
            </center>
          </div>

          <div className={nav["search-social"]}>
            <div onClick={() => navigate("/search")}>
              <HiSearch
                className={
                  iconBlack === true
                    ? nav.searchIconBlack
                    : iconWhite === true
                    ? nav.searchIconWhite
                    : nav.searchIcon
                }
                style={{ transform: "rotateY(180deg)" }}
              />
            </div>
            {contactInfo?.map((contact) =>
              // Linkedin
              contact.type === "linkedin" ? (
                <a href={contact.value} target="_blank">
                  <ImLinkedin2
                    className={
                      iconBlack === true
                        ? nav.linkedinIconBlack
                        : iconWhite === true
                        ? nav.linkedinIconWhite
                        : nav.linkedinIcon
                    }
                  />
                </a>
              ) : // Twitter
              contact.type === "twitter" ? (
                <a href={contact.value} target="_blank">
                  <ImTwitter
                    className={
                      iconBlack === true
                        ? nav.linkedinIconBlack
                        : iconWhite === true
                        ? nav.linkedinIconWhite
                        : nav.linkedinIcon
                    }
                  />
                </a>
              ) : contact.type === "instagram" ? (
                <a href={contact.value} target="_blank">
                  <ImInstagram
                    className={
                      iconBlack === true
                        ? nav.linkedinIconBlack
                        : iconWhite === true
                        ? nav.linkedinIconWhite
                        : nav.linkedinIcon
                    }
                  />
                </a>
              ) : (
                ""
              )
            )}
          </div>
        </div>
        <div
          className={open === true ? nav.darkOverlayActive : nav.darkOverlay}
          onClick={handleMenuClick}
        ></div>
      </nav>
      {/* SEARCH PAGE  */}

      {/* HEADER */}
      <header className={nav.header}>
        <div className={nav.navMobile}>
          <div className={nav.menu}>
            <center>
              {open === true ? (
                <HiOutlineMinus
                  onClick={handleMenuClick}
                  className={nav.menuOpenIcon}
                  id="menu-icon"
                />
              ) : (
                <HiOutlineMenuAlt4
                  onClick={handleMenuClick}
                  className={
                    iconBlack === true
                      ? nav.menuIconBlack
                      : iconWhite === true
                      ? nav.menuIconWhite
                      : nav.menuIcon
                  }
                  id="menu-icon"
                />
              )}
            </center>
          </div>

          <div className={nav["search-social"]}>
            <div onClick={() => navigate("/search")}>
              <HiSearch
                className={
                  iconBlack === true
                    ? nav.searchIconBlack
                    : iconWhite === true
                    ? nav.searchIconWhite
                    : nav.searchIcon
                }
                style={{ transform: "rotateY(180deg)" }}
              />
            </div>
          </div>
        </div>

        <Link to="/">
          <img
            id="logo"
            onClick={HandleOtherPages}
            className={nav.logo}
            src={darkMode === true ? darkLogo : logo}
            alt=""
          />
        </Link>
        {/* <div className={nav.hamburger}>
          <li style={{ paddingRight: "0px" }}></li>
          <svg
            className={
              active
                ? `${nav.ham} ${nav.active} ${nav.hamRotate} ${nav.ham1}`
                : `${nav.ham}  ${nav.hamRotate} ${nav.ham1}`
            }
            id={nav.linecolor}
            viewBox="0 0 100 100"
            width="50"
            onClick={handleMenuClick}
          >
            <path
              className={`${nav.top} ${nav.line}`}
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            />
            <path className={`${nav.middle} ${nav.line}`} d="m 30,50 h 40" />
            <path
              className={`${nav.bottom} ${nav.line}`}
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            />
          </svg>
        </div> */}
        <Link
          to="contact-us"
          className={darkMode === true ? nav.darkHeader : nav.lightHeader}
          onClick={HandleGreyPages}
        >
          CONTACT US
        </Link>
      </header>
    </>
  );
}

export default Nav;
