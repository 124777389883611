import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Company"],
});

const companyApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => `/company/profile`,
      providesTags: ["Company"],
    }),
    getCulture: builder.query({
      query: () => `/company/culture`,
      providesTags: ["Company"],
    }),
    getContact: builder.query({
      query: () => `/company/contact`,
      providesTags: ["Company"],
    }),
    getAbout: builder.query({
      query: () => `/company/about`,
      providesTags: ["Company"],
    }),
    getTeam: builder.query({
      query: () => `/company/team`,
      providesTags: ["Company"],
    }),
    getTeamMember: builder.query({
      query: (parameter) => `/company/team/${parameter}`,
      providesTags: ["Company"],
    }),
    getDocuments: builder.query({
      query: () => "/company/documents",
      providesTags: ["Company"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetAboutQuery,
  useGetContactQuery,
  useGetCultureQuery,
  useGetTeamQuery,
  useGetTeamMemberQuery,
  useGetDocumentsQuery,
} = companyApiSlice;
