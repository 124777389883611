import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Segments"],
});

const segmentsApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSegments: builder.query({
      query: () => `/segment`,
      providesTags: ["Segments"],
    }),
  }),
});

export const { useGetSegmentsQuery } = segmentsApiSlice;
