import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import owl from "../home/owl-carousel/owl.module.css";
import home from "./home.module.css";
import insignia from "../../images/insignia.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import Carousel from "./carousel/Carousel";
import CarouselTwo from "./owl-carousel/CarouselTwo";
import RefCarousel from "./ref-carousel/RefCarousel";
import { useGetNewsQuery } from "../news/newsApiSlice";
import { useGetSegmentsQuery } from "../segments/segmentsApiSlice";
import { useGetReferencesQuery } from "../references/referencesApiSlice";
import { format } from "date-fns";
import parse from "html-react-parser";
import { Player, BigPlayButton } from "video-react";
import { useState } from "react";
import { useAddViewMutation } from "../../helpers/dashboardApiSlice";
import axios from "axios";
import { detect } from "detect-browser";
import { useInView } from "react-intersection-observer";
import { useGetDocumentsQuery } from "../culture/companyApiSlice";
import Footer from "../../layout/Footer";
import { useMediaQuery } from "@mui/material";
import { FiChevronRight } from "react-icons/fi";
import Masonry from "react-masonry-css";
import LazyLoad from "react-lazyload";
import ImageKit from "../../helpers/ImageKit";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

function Home(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileView = useMediaQuery("(max-width:500px)");
  const [user, setUser] = useState(""); // state to store Client IP address
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  const { data: documents, isLoading: docsLoading } = useGetDocumentsQuery();
  const { data: ref, isLoading: referencesLoading } = useGetReferencesQuery();

  const video = documents?.documents?.find((doc) => doc.type === "video");

  const [addView, { isLoading: isAddingView }] = useAddViewMutation();

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    if (res) {
      setUser(res.data?.IPv4);
    }
  };

  function removeHTMLTags(html) {
    var temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || "";
  }

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  // GET BROWSER NAME
  const clientBrowser = detect();

  // POST NEWS VIEW TO DASHBOARD
  const handleView = async () => {
    if (user === "") {
    } else {
      try {
        const body = {
          news_id: null,
          reference_id: null,
          is_landing_page: true,
          ip: user,
          browser: clientBrowser?.name,
        };

        await addView(body).unwrap();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleView();
  }, [user]);

  const newsLimit = 6;
  const { data, isLoading: newsLoading } = useGetNewsQuery(newsLimit);
  const headlines = data?.news.slice(0, 6);
  // console.log("news", headlines);

  const { data: segm, isLoading: segmentsLoading } = useGetSegmentsQuery();
  const segments = segm?.segments;
  //   Animate on scroll
  // useEffect(() => {
  // }, []);

  window.addEventListener("load", Aos.init({ duration: 400 }));

  // const trunk = document.getElementsByClassName("segmentText");
  // useEffect(() => {
  //   shave(trunk, 120, { classname: "segmentText" });
  // }, [segments]);

  // shave('.shave-selector', 0) for example

  //  FUNCTIONS FOR HEADER & NAV ICONS' TRANSITION ON SCROLL

  // const greyRef = useRef(); //ref for gray section
  //const darkRef = useRef(); //ref for dark business portfolio section

  // const { ref: greyRef, inView: greyInView } = useInView({
  //   threshold: 0.1,
  // });

  // const { ref: greyRefTwo, inView: greyTwoInView } = useInView({
  //   threshold: 0,
  // });

  // const { ref: darkRef, inView: darkInView } = useInView({
  //   threshold: 0.1,
  // });

  // const { ref: bottomDark, inView: bottomDarkInView } = useInView({
  //   threshold: 0.1,
  // });

  // var getElemDistance = function (elem) {
  //   var location = 0;
  //   if (elem?.offsetParent) {
  //     do {
  //       location += elem?.offsetTop;
  //       elem = elem?.offsetParent;
  //     } while (elem);
  //   }
  //   return location >= 0 ? location : 0;
  // };

  // var greySection = document.querySelector("#greySection");
  // var greyLocation = getElemDistance(greySection);
  // const greyDist = (greyLocation / window.innerHeight) * 100 - 75;
  // // console.log("grey to top", greyDist);

  // var darkSection = document.querySelector("#darkSection");
  // var darkLocation = getElemDistance(darkSection);
  // const darkDist = (darkLocation / window.innerHeight) * 100 - 8;
  // // console.log("dark to top", darkDist);

  // var bottomDarkSection = document.querySelector("#bottomDark");
  // var bottomDarkLocation = getElemDistance(bottomDarkSection);
  // const bottomDarkDist = (bottomDarkLocation / window.innerHeight) * 100 - 65;
  // // console.log("dark to top", darkDist);

  // const [bgcolor, setBgcolor] = useState("white");

  // function HandleColorTransition() {
  //   let scrollValue = (window.scrollY / window.innerHeight) * 100;

  //   if (
  //     (scrollValue >= darkDist && darkInView === true) ||
  //     bottomDarkInView === true
  //   ) {
  //     props?.setDarkHeader(true);
  //     props?.setWhiteIcons(true);
  //     setBgcolor("dark");
  //   } else if (
  //     scrollValue >= greyDist &&
  //     (greyInView === true || greyTwoInView === true)
  //   ) {
  //     props?.setWhiteIcons(true);
  //     props?.setDarkHeader(true);
  //     setBgcolor("grey");
  //   } else if (scrollValue >= bottomDarkDist && bottomDarkInView === true) {
  //     props?.setDarkHeader(true);
  //     props?.setWhiteIcons(true);
  //     setBgcolor("dark");
  //   } else {
  //     props?.setDarkHeader(false);
  //     props?.setWhiteIcons(false);
  //     setBgcolor("white");
  //   }
  //   // console.log("Grey intersecting", greyDist);
  //   // console.log("Dark intersecting", darkDist);
  //   // console.log("scrollvalue", scrollValue);
  // }

  // if (location.pathname === "/") {
  //   window.addEventListener("scroll", HandleColorTransition);
  // } else {
  //   window.removeEventListener("scroll", HandleColorTransition);
  // }

  // function testing() {
  //   const testVar = (window.scrollY / window.innerHeight) * 100;
  //   console.log("distance to top", testVar);
  // }
  // window.addEventListener("scroll", testing);
  const breakpoints = {
    default: 2,
    1100: 1,
  };

  return (
    <body className={home.body}>
      <section className={home.firstSection}>
        <div
          className={home.firstSlideshow}
          data-aos="fade-in"
          data-aos-duration={800}
        >
          <Carousel />
        </div>
      </section>

      <main className={home.main}>
        <section className={home.imageContainer} id="greySection">
          <div className={home.video}>
            <LazyLoad>
              <Player
                width={mobileView ? 350 : 1100}
                height={mobileView ? 300 : 550}
                fluid={false}
                poster={video?.thumbnail}
                src={video?.url}
              >
                <BigPlayButton position="center" />
              </Player>
            </LazyLoad>
          </div>
        </section>

        {/*                SEGMENTS                     */}
        <section className={home.segments} id="greySection">
          <div className={home.container} id={home.segmentsHeading}>
            <h2>SEGMENTS</h2>
          </div>

          <div className={home["segments-items"]}>
            <div className={home.segmentsContainer}>
              <div className={home.items}>
                <Masonry
                  breakpointCols={breakpoints}
                  className="my-masonry-grid"
                  id="greySection"
                  columnClassName="my-masonry-grid_column"
                >
                  {segments?.map((segment, i) => (
                    <div
                      key={i}
                      className={home.item}
                      data-aos="fade-in"
                      data-aos-duration={800}
                    >
                      <p className={home.segmentNumber}>0{i + 1}</p>
                      <span className={home.segmentTitle}>{segment?.name}</span>

                      <div className={home.textContainer}>
                        <p id={home.segmentText} className={`segmentText`}>
                          <ResponsiveEllipsis
                            text={removeHTMLTags(
                              `${
                                segment?.blocks?.find(
                                  (block) => block?.type === "text"
                                )?.content
                              }`
                            )}
                            maxLine="6"
                            ellipsis="..."
                            trimRight
                            basedOn="words"
                          />{" "}
                          {/* {parse(
                            `${
                              segment?.blocks?.find(
                                (block) => block?.type === "text"
                              )?.content
                            }`
                          )} */}
                        </p>
                      </div>

                      <a
                        onClick={() => {
                          navigate("/segments", {
                            state: {
                              id: segment.id,
                            },
                          });
                        }}
                      >
                        MORE
                      </a>
                    </div>
                  ))}
                </Masonry>
              </div>
            </div>
          </div>
        </section>

        {/*                 BUSINESS PORTFOLIO OWL CAROUSEL                    */}
        <section className={home["business-portfolio"]} id="darkSection">
          <div className={home["container-2"]}>
            <h2>BUSINESS PORTFOLIO </h2>
            <CarouselTwo
              btn={
                <button
                  className={
                    home.learnMoreWhite
                    // bgcolor === "dark"
                    //   ? home.learnMoreBlack
                    //   : home.learnMoreBlack
                  }
                >
                  <div style={{ paddingTop: "1.5px" }}> Learn More </div>
                  <FiChevronRight />
                  {/* <div className={home.portfolioSpan}></div> */}
                </button>
              }
            />
          </div>
        </section>

        <section className={home.references}>
          <div className={home["container-2"]}>
            <h2
              className={
                home.textBlack
                // bgcolor === "dark" ? home.textWhite : home.textBlack
              }
            >
              REFERENCES
            </h2>
            <p
              className={
                home.textBlack
                // bgcolor === "dark" ? home.textWhite : home.textBlack
              }
            >
              {ref?.meta?.title}
            </p>

            <RefCarousel
              btn={
                <button
                  className={
                    home.moreProjectsWhite
                    // bgcolor === "dark"
                    //   ? home.moreProjectsBlack
                    //   : home.moreProjectsWhite
                  }
                >
                  <div
                    className={
                      home.textBlack
                      // bgcolor === "dark" ? home.textWhite : home.textBlack
                    }
                  >
                    {" "}
                    MORE PROJECTS{" "}
                  </div>
                  <div className="span">
                    <FiChevronRight />
                  </div>
                </button>
              }
            />
          </div>
        </section>
        <div id="darkSection">
          <section className={home.news}>
            <div className={home["container-2"]}>
              <h2
                className={
                  home.textBlack
                  // bgcolor === "dark" ? home.textWhite : home.textBlack
                }
              >
                NEWS
              </h2>
            </div>
            <div className={home.container}>
              <div className={home.items}>
                <div className={home.newsFlexbox}>
                  {headlines?.map((news, i) => (
                    <div
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration={800}
                      className={
                        `${home.item} ${home.borderBlack}`
                        // bgcolor === "dark"
                        //   ? `${home.item} ${home.borderWhite}`
                        //   : `${home.item} ${home.borderBlack}`
                      }
                    >
                      <Link
                        to={`news/news-post/${news.id}`}
                        state={{ id: news.id }}
                      >
                        <h5
                          className={
                            home.textBlack
                            // bgcolor === "dark" ? home.textWhite : home.textBlack
                          }
                        >
                          {news?.title}
                        </h5>
                        <h6
                          className={
                            home.textBlack
                            // bgcolor === "dark" ? home.textWhite : home.textBlack
                          }
                        >
                          {format(new Date(news?.published_at), "PPP")}
                        </h6>
                      </Link>
                    </div>
                  ))}
                </div>

                <div className={home.readMoreFlex}>
                  <Link
                    className={
                      home.readMoreBlack
                      // bgcolor === "dark"
                      //   ? home.readMoreWhite
                      //   : home.readMoreBlack
                    }
                    to="news"
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className={home.join} id="darkBottom">
            <div className={home.container}>
              <img
                src={insignia}
                height="auto"
                className={home.insignia}
                alt=""
              />
              <div className={home.joinText}>
                <h2>Join Our Team</h2>
                <Link to="careers">VIEW OUR OPEN POSITIONS</Link>
              </div>
            </div>
          </section>
          <Footer style={home} />
        </div>
      </main>
    </body>
  );
}

export default Home;
