import { React, useState } from "react";
import home from "../home.module.css";
import "../carousel/flickity.css";
import placeholderImg from "../../../images/placeholder_img.png";
import { FiChevronRight } from "react-icons/fi";
import "./ref.css";
import { useGetReferencesQuery } from "../../references/referencesApiSlice";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import Flickity from "react-flickity-component";
import asNavFor from "flickity-as-nav-for";
import flickitySync from "flickity-sync";
import ImageKit from "../../../helpers/ImageKit";
import LazyLoad from "react-lazyload";

function RefCarousel(props) {
  const { data, isLoading: referencesLoading } = useGetReferencesQuery();

  const refLength = data?.references?.length;

  const mainOptions = {
    pageDots: false,
    autoPlay: 5000,
    wrapAround: true,
    bgLazyLoad: true,
    draggable: true,
    prevNextButtons: false,
    pauseAutoPlayOnHover: false,
    freeScroll: true,
    freeScrollFriction: 0.01,
    selectedAttraction: 0.015,
    friction: 0.25,

    // asNavFor: ".carousel-main-2",
  };

  const navOptions = {
    sync: ".carousel-main-2",
    asNavFor: ".carousel-main-2",
    contain: true,
    pageDots: true,
    bgLazyLoad: true,
    draggable: false,
    wrapAround: true,
    prevNextButtons: false,
    pauseAutoPlayOnHover: false,
    autoPlay: false,
    freeScroll: true,
    freeScrollFriction: 0.01,
    selectedAttraction: 0.015,
    friction: 0.25,
  };
  return (
    <>
      <section id="references">
        {referencesLoading ? (
          <div className="loadingDiv">
            <CircularProgress fontSize={24} sx={{ color: "#874834" }} />
          </div>
        ) : (
          <>
            <Flickity
              id="carousel-main-2"
              className="carousel carousel-main-2"
              options={mainOptions}
              //  className={"carousel carousel-main-2"} // default ''
              // elementType={"div"} // default 'div'
              // options={refOptions} // takes flickity options {}
            >
              {data?.references?.map(
                (reference, i) =>
                  reference?.is_featured && (
                    <LazyLoad style={{ height: "80vh", width: "100%" }} key={i}>
                      <div
                        key={i}
                        className="carousel-cell"
                        style={{
                          backgroundImage: `radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), url(${
                            ImageKit({url:reference?.featured_image}) || placeholderImg
                          })`,
                        }}
                      >
                        <div className="titleBox">
                          <h1>{reference?.title}</h1>
                        </div>
                        <Link
                          to={`references/reference/${reference.id}`}
                          state={{ id: reference.id }}
                        >
                          <span style={{ paddingTop: "4.7px" }}>
                            MORE DETAILS
                          </span>
                          <FiChevronRight />
                        </Link>
                      </div>
                    </LazyLoad>
                  )
              )}
            </Flickity>

            {/* CAROUSEL NAV */}

            <Flickity
              id="ref-nav"
              className="carousel ref-nav"
              options={navOptions}
              //className={"carousel ref-nav"} // default ''
              // elementType={"div"} // default 'div'
              // options={refNavOptions} // takes flickity options {}
            >
              {data?.references?.map(
                (reference, i) =>
                  reference?.is_featured && (
                    <div
                      key={i}
                      className="carousel-cell"
                      style={{ width: `${refLength})` }}
                    >
                      <div className="navCell">
                        {" "}
                        <p>{reference?.title}</p>
                      </div>
                    </div>
                  )
              )}
            </Flickity>
          </>
        )}

        <div className="moreBtn">
          <Link to="references">{props.btn}</Link>
        </div>
      </section>
    </>
  );
}

export default RefCarousel;
