import React from "react";
import { Link } from "react-router-dom";
import { useGetPagesQuery } from "../pages/page/pageApiSlice";

function Footer(props) {

    const { data, isLoading } = useGetPagesQuery()

    return (
    <footer className={props.style.footer}>
        <div className={props.style.container}>
          <div className={props.style.moreInfo}>
            <ul>
            {
                data?.pages?.map((page)=>{
                    return (
                        <li>
                            <Link
                            to={`/page/${page.slug}`}
                            style={{ height: "100%", width: "100%" }}
                            >{page.title}</Link>
                        </li>
                    )
                })
            }
              <li>
                <Link to="/contact-us">CONTACT US</Link>
              </li>
            </ul>
          </div>
          <div className={props.style.copywrite}>
            <p>{`© ${new Date().getFullYear()}  PrimeTech Design & Engineering Ltd.`}</p>
          </div>
        </div>
      </footer>
    )
}
export default Footer;