import React from "react";
import { useGetCategoryQuery } from "./newsApiSlice";
import news from "./news.module.css";
import placeholderImg from "../../images/placeholder_img.png";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import ImageKit from "../../helpers/ImageKit";

function NewsCategory(category) {
  const newsCategory = category?.category;

  const { data, isLoading: categoryLoading } = useGetCategoryQuery(
    newsCategory?.id
  );

  return (
    <>
      {categoryLoading ? (
        <div className={news.noNews}>
          <CircularProgress sx={{ color: "rgba(0,0,0,0.4)" }} />
        </div>
      ) : data?.news?.length > 0 ? (
        <div className={news.items}>
          {data?.news?.map((post) => (
            <div
              key={post.id}
              className={news.item}
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
            >
              <Link
                to="news-post"
                state={{ id: post.id }}
                style={{ height: "100%", width: "100%" }}
              >
                <img
                  className={news.image}
                  src={
                    ImageKit({url:post?.featured_image}) ||
                    placeholderImg
                  }
                  alt={post?.title}
                />
              </Link>
              <p>{post?.title || "Headline"}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className={news.noNews}>
          <em>There is currently no news in this category...</em>
        </p>
      )}
    </>
  );
}

export default NewsCategory;

