import React, { useEffect } from "react";
import primeTech1 from "../../images/PrimeTech_01.jpg";
import segmentsImage from "../../images/segments-images/DJI_0860.jpg";
import { Link, Navigate, useLocation } from "react-router-dom";
import segments from "./segments.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useGetSegmentsQuery } from "./segmentsApiSlice";
import "./slick.css";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageKit from "../../helpers/ImageKit";
import { CircularProgress } from "@mui/material";
import Footer from "../../layout/Footer";

function Segments(props) {
  const { data, isLoading: segmentsLoading } = useGetSegmentsQuery();
  const location = useLocation();
  const segmentId = location?.state?.id; // to scroll to if coming from search

  const segData = data?.segments;

  const metaText = data?.meta?.blocks?.find((block) => block?.type === "text");

  const metaImage = data?.meta?.blocks?.find(
    (block) => block?.type === "image"
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    // scroll to content if coming from search page
    if (segmentId) {
      document?.getElementById(segmentId)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [segmentId, segData]);

  return (
    <body className={segments.body} onClick={() => props.setMenuOpen(false)}>
      <main className={segments.main}>
        <section className={segments.sectionOne}>
          <div
            className={segments["container-2"]}
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
          >
            <img
              src={metaImage?.media?.[0]?.url}
              alt=""
              className={segments["image-size"]}
            />
          </div>
          <div
            className={segments.container}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
          >
            <h3>{parse(metaText?.content || "")}</h3>
          </div>
        </section>

        <section className={segments.section}>
          <div className={segments.container}>
            {segmentsLoading ? (
              <div className={segments.loading}>
                <CircularProgress sx={{ color: "rgba(0,0,0,0.4)" }} />
              </div>
            ) : (
              <div id={segments["section-nav"]}>
                <div className={segments.navFlex}>
                  {segData?.map((seg, i) => (
                    <span key={i}>
                      <a href={`#${seg.id}`}>{seg.name}</a>
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>

        {segData ? (
          <section className={`${segments.second} ${segments.section}`}>
            {segData?.map((seg, i) => (
              <div className={segments.segContainer} id={`${seg?.id}`} key={i}>
                <div className={segments.items}>
                  <h6>0{i + 1}</h6>
                  <div
                    className={segments["item-one"]}
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                  >
                    <h3>{seg?.name}</h3>
                  </div>

                  <div
                    className={segments["item-two"]}
                    data-aos="fade-in"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                  >
                    {seg?.blocks
                      ?.filter((block) => block?.type === "text")
                      .map((block) => (
                        <div className={segments.textBlock} key={block.id}>
                          {parse(block?.content)}
                        </div>
                      ))}
                  </div>
                </div>

                {seg?.blocks?.find((block) => block?.type === "image") ? (
                  <Slider {...settings} className={segments.slickSlider}>
                    {seg?.blocks
                      ?.find((block) => block?.type === "image")
                      .media?.map((picture, i) => (
                        <div
                          key={i}
                          data-aos="fade-up"
                          data-aos-offset="200"
                          data-aos-easing="ease-in-sine"
                        >
                          <img
                            src={ImageKit({url:picture?.url})}
                            alt={seg?.name}
                          />
                        </div>
                      ))}{" "}
                  </Slider>
                ) : (
                  ""
                )}
              </div>
            ))}
          </section>
        ) : (
          ""
        )}

        <section
          className={`${segments.view} ${segments.section}`}
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
        >
          <div className={segments.container}>
            <Link to="/contact-us">
              <h3>
                TALK TO OUR <br /> EXPERTS
              </h3>
            </Link>
            <Link to="/references">
              <h3>
                VIEW OUR <br /> FEATURED PROJECTS
              </h3>
            </Link>
          </div>
        </section>
      </main>

      <Footer style={segments} />
    </body>
  );
}

export default Segments;
