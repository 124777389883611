import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import posts from "./page.module.css";
import Aos from "aos";
import "../home/carousel/flickity.css";
import "aos/dist/aos.css";
import parse from "html-react-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../segments/slick.css";
import { useGetPageQuery } from "./pageApiSlice";
import ImageKit from "../../helpers/ImageKit";
import { CircularProgress } from "@mui/material";
import Footer from "../../layout/Footer";

function Page(props) {
  const location = useLocation();
  let { pageSlug } = useParams();

  const { data, isLoading } = useGetPageQuery(pageSlug);

  const page = data?.page;

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <body className={posts.body} onClick={() => props.setMenuOpen(false)}>
      <main className={posts.main}>
        <div className={posts.mainContainer}>
          <div className={posts["mini-navbar"]}>
            <h3 className={posts.date}>
              {/* {page ? format(new Date(page?.published_at), "PPP") : ""} */}
            </h3>
            {/* <h3 className={posts["back-btn"]}>
              <Link to="/news">BACK</Link>
            </h3> */}
          </div>
          {isLoading && (
            <div className={posts.loading}>
              <CircularProgress sx={{ color: "lightgray" }} />
            </div>
          )}
          <div className={posts["main-header"]}>
            <h1>{page?.title || ""}</h1>
          </div>
          {page?.blocks?.map((block) =>
            block.type === "text" ? (
              <div className={posts.newsBlock}>
                <p>{parse(block?.content) || ""}</p>
              </div>
            ) : (
              <div className={posts.newsBlock}>
                <Slider {...settings}>
                  {block?.media?.map((mediaItem, i) => (
                    <div key={i}>
                      <img
                        src={ImageKit({url:mediaItem?.url})}
                        alt={block?.caption || ""}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )
          )}
        </div>
      </main>
      <Footer style={posts}/>
    </body>
  );
}

export default Page;
