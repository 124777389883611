import { Stack } from "@mui/material";
import React from "react";
import { GoSearch } from "react-icons/go";
import AnimCursor from "../../customComponents/cursor/animated-cursor/AnimCursor";
import styles from "./search.module.css";

function SearchInput({ value, setValue }) {
  return (
    <div id="searchPage" className={styles.searchPage}>
      <AnimCursor menuToggle={true} />
      <div className={styles.searchBox}>
        <form>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderBottom: "2px solid",
              borderColor: "rgba(255,255,255, 0.5)",
              padding: "15px",
            }}
          >
            <input
              type="text"
              name="searchbar"
              id="searchbar"
              placeholder="Start typing to search"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  document.getElementById("search-result").scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }
              }}
            />
            <div>
              <GoSearch
                fontSize="2.5rem"
                className={styles.inputIcon}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("search-result").scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
              />
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
}

export default SearchInput;
