import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Reference"],
});

const referencesApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getReferences: builder.query({
      query: () => `/reference`,
      providesTags: ["Reference"],
    }),
    getSingleReference: builder.query({
      query: (parameter) => `/reference/${parameter}`,
      providesTags: ["Reference"],
    }),
  }),
});

export const { useGetReferencesQuery, useGetSingleReferenceQuery } =
  referencesApiSlice;
