import React, { useEffect } from "react";
import "../home/carousel/flickity.css";
import primeTech1 from "../../images/PrimeTech_01.jpg";
import placeholderImg from "../../images/placeholder_img.png";
import { Link, Navigate } from "react-router-dom";
import culture from "./culture.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useGetCultureQuery } from "./companyApiSlice";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../segments/slick.css";
import ImageKit from "../../helpers/ImageKit";
import { CircularProgress } from "@mui/material";
import Footer from "../../layout/Footer";

function Culture(props) {
  const { data: cultures, isLoading: cultureLoading } = useGetCultureQuery();

  const texts = cultures?.culture?.blocks?.filter(
    (block) => block?.type === "text"
  );
  const images = cultures?.culture?.blocks?.filter(
    (block) => block?.type === "image"
  );

  const metaImage = cultures?.meta?.blocks?.find(
    (block) => block?.type === "image"
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 400 });
  }, []);
  return (
    <body className={culture.body} onClick={() => props.setMenuOpen(false)}>
      <main className={culture.main}>
          <div className={culture.container}>
            <h1>CORPORATE CULTURE</h1>
          </div>
        <section className={`${culture.sectionOne} `}>
          <div className={culture.mainBanner} style={{backgroundImage:
            `url(${
              ImageKit({url: metaImage?.media?.[0]?.url}) || ""
            })`
          
          }}></div>
        </section>

        <section className={culture.section}>
          <div className={culture.container}>
            {cultureLoading ? (
              <div className={culture.loading}>
                <CircularProgress sx={{ color: "lightgray" }} />
              </div>
            ) : (
              <div id={culture["section-nav"]}>
                <ul>
                  {images?.map((image, i) => (
                    <li key={i}>
                      <a href={`#${image?.caption.toLowerCase()}`}>
                        {image?.caption}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </section>

        {cultures
          ? texts?.map((text, i) => (
              <section
                key={i}
                className={culture.blockContainer}
                id={images[i].caption.toLowerCase()}
              >
                <div
                  className={culture.cultureText}
                  data-aos="fade-in"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                >
                  <h1>{images[i].caption}</h1>

                  <p>{parse(text?.content)}</p>

                  <Slider {...settings} className={culture.imageDiv}>
                    {images?.[i].media?.map((image, d) => (
                      <div key={d}>
                        <img
                          src={ImageKit({url:image?.url}) || ""}
                          alt={images[i]?.caption}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              
              </section>
              
            ))
          : ""}
                <section
        className={`${culture.view} ${culture.section}`}
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
      >
        <div className={culture.container}>
          <Link to="/segments">
            <h3>
              VIEW OUR <br /> SERVICES
            </h3>
          </Link>
          <Link to="/references">
            <h3>
              VIEW OUR <br /> FEATURED PROJECTS
            </h3>
          </Link>
        </div>
      </section>


      </main>

      <Footer style={culture}/>
    </body>
  );
}

export default Culture;
