import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import posts from "./newsPost.module.css";
import Aos from "aos";
import "../../home/carousel/flickity.css";
import "aos/dist/aos.css";
import parse from "html-react-parser";
import { format } from "date-fns";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../segments/slick.css";
import { useGetNewsPostQuery } from "../newsApiSlice";
import { detect } from "detect-browser";
import axios from "axios";
import { useAddViewMutation } from "../../../helpers/dashboardApiSlice";
import ImageKit from "../../../helpers/ImageKit";
import { CircularProgress } from "@mui/material";
import Footer from "../../../layout/Footer";

function NewsPost(props) {
  const location = useLocation();
  const id = useParams();
  const newsId = id.id;
  // const newsId = location.state?.id;

  const [user, setUser] = useState(""); // state to store Client IP address

  const [addView, { isLoading: isAddingView }] = useAddViewMutation();

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    if (res) {
      setUser(res.data?.IPv4);
    }

    console.log(res.data);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method

    getData();
  }, []);

  // GET BROWSER NAME
  const clientBrowser = detect();
  if (clientBrowser) {
    console.log(clientBrowser?.name);
  }

  // POST NEWS VIEW TO DASHBOARD
  const handleView = async () => {
    if (user === "") {
    } else {
      try {
        const body = {
          news_id: newsId,
          reference_id: null,
          is_landing_page: false,
          ip: user,
          browser: clientBrowser?.name,
        };

        const response = await addView(body).unwrap();

        if (response) {
          console.log(response, "response");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleView();
  }, [user]);

  const { data, isLoading } = useGetNewsPostQuery(newsId);
  console.log(newsId, "newsData");

  const newsPost = data?.news;

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  function removeTime(date) {
    return new Date(date?.toDateString());
  }
  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <body className={posts.body} onClick={() => props.setMenuOpen(false)}>
      <main className={posts.main}>
        <div className={posts.mainContainer}>
          <div className={posts["mini-navbar"]}>
            <h3 className={posts.date}>
              {newsPost ? format(new Date(newsPost?.published_at), "PPP") : ""}
            </h3>
            <h3 className={posts["back-btn"]}>
              <Link to="/news">BACK</Link>
            </h3>
          </div>
          {isLoading && (
            <div className={posts.loading}>
              <CircularProgress sx={{ color: "lightgray" }} />
            </div>
          )}
          <div className={posts["main-header"]}>
            <h1>{newsPost?.title || ""}</h1>
          </div>
          {newsPost?.blocks?.map((block) =>
            block.type === "text" ? (
              <div className={posts.newsBlock}>
                <p>{parse(block?.content) || ""}</p>
              </div>
            ) : (
              <div className={posts.newsBlock}>
                <Slider {...settings}>
                  {block?.media?.map((mediaItem, i) => (
                    <div key={i}>
                      <img
                        src={ImageKit({url:mediaItem?.url})}
                        alt={block?.caption || ""}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )
          )}
        </div>
      </main>
      <Footer style={posts} />
    </body>
  );
}

export default NewsPost;
