import React from "react";
import people from "./people.module.css";
import { useLocation } from "react-router-dom";
import { Avatar, CircularProgress } from "@mui/material";
import parse from "html-react-parser";
import "../../home/carousel/flickity.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../segments/slick.css";
import { ImLinkedin2 } from "react-icons/im";
import { useGetTeamMemberQuery } from "../../culture/companyApiSlice";
import ImageKit from "../../../helpers/ImageKit";
import Footer from "../../../layout/Footer";

function People(props) {
  const location = useLocation();
  const profileId = location?.state?.id;

  const { data, isLoading: teamMemberLoading } =
    useGetTeamMemberQuery(profileId);

  const profile = data?.team;

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <body className={people.body} onClick={() => props.setMenuOpen(false)}>
      <main className={people.main}>
        {teamMemberLoading ? (
          <div className={people.mainContainer}>
            <div className={people.loadingDiv}>
              <CircularProgress sx={{ color: "lightgray" }} />
            </div>
          </div>
        ) : (
          <>
            <section className={people.sectionOne}>
              <div className={people.mainContainer}>
                <div
                  className={people["main-header"]}
                  style={{
                    backgroundImage: `url(${
                      ImageKit({url:profile?.image?.url}) || <Avatar />
                    })`,
                  }}
                >
                  <h2>{profile?.name}</h2>
                  <h3>{profile?.position}</h3>
                </div>
              </div>
            </section>
            <section className={people.section}>
              <div className={people["container-2"]}>
                <p
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                >
                  {parse(`${profile?.bio}`)}
                </p>

                <div
                  className={people.socials}
                  data-aos="fade-right"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                >
                  {
                  (profile?.social && profile?.social!=='-') &&
                  <a href={profile?.social || ""} target="_blank">
                    <ImLinkedin2 />
                  </a>
                  }
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      {/* Add Footer Here */}
      <Footer style={people}/>
    </body>
  );
}

export default People;
