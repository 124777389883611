import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Careers"],
});

const careerApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCareers: builder.query({
      query: () => `/career`,
      providesTags: ["Careers"],
    }),
    getSingleCareer: builder.query({
      query: (parameter) => `/career/${parameter}`,
      providesTags: ["Careers"],
    }),
  }),
});

export const { useGetCareersQuery, useGetSingleCareerQuery } = careerApiSlice;
