import React, { useEffect } from "react";

import placeholderImg from "../../images/placeholder_img.png";
import refTwo from "./refTwo.module.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { useGetPortfolioQuery } from "../business-portfolio/portfolioApiSlice";
import Aos from "aos";
import "../home/carousel/flickity.css";
import "aos/dist/aos.css";
import parse from "html-react-parser";
import { useGetSingleReferenceQuery } from "./referencesApiSlice";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useAddViewMutation } from "../../helpers/dashboardApiSlice";
import axios from "axios";
import { detect } from "detect-browser";
import ImageKit from "../../helpers/ImageKit";
import Footer from "../../layout/Footer";

function Ref(props) {
  const location = useLocation();
  const id = useParams();
  const referenceId = id.id;
  // const referenceId = location.state?.id;

  const [user, setUser] = useState(""); // state to store Client IP address

  const [addView, { isLoading: isAddingView }] = useAddViewMutation();

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    if (res) {
      setUser(res.data?.IPv4);
    }
    console.log(res.data);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method

    getData();
  }, []);

  // GET BROWSER NAME
  const clientBrowser = detect();
  if (clientBrowser) {
    console.log(clientBrowser?.name);
  }

  // POST NEWS VIEW TO DASHBOARD
  const handleView = async () => {
    if (user === "") {
    } else {
      try {
        const body = {
          news_id: null,
          reference_id: referenceId,
          is_landing_page: false,
          ip: user,
          browser: clientBrowser?.name,
        };

        const response = await addView(body).unwrap();

        if (response) {
          console.log(response, "response");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleView();
  }, [user]);

  // GET REFERENCE DATA FROM ENDPOINT
  const { data, isLoading: referenceLoading } =
    useGetSingleReferenceQuery(referenceId);
  const { data: portfolio, isLoading } = useGetPortfolioQuery();

  const reference = data?.reference;

  const portfolioName = portfolio?.portfolios?.find(
    (item) => item?.id === reference?.portfolio_id
  )?.name;

  const textBlocks = reference?.blocks?.filter(
    (block) => block?.type === "text"
  );
  const imageBlock = reference?.blocks?.find(
    (block) => block?.type === "image"
  );

  (function () {
    const FlickityElement = require("flickity");
    require("flickity-imagesloaded");
    require("flickity-fade");
    require("flickity-bg-lazyload");

    const projectCarousel = document.getElementById("showcase");

    var slideshow = new FlickityElement(projectCarousel, {
      pageDots: false,
      fade: false,
      contain: true,
      cellAlign: "left",
      autoPlay: 10000,
      wrapAround: true,
      freeScroll: false,
      bgLazyLoad: true,
      draggable: true,
      prevNextButtons: false,
      pauseAutoPlayOnHover: false,
      accessibility: true,
    });

    function TryMe() {
      slideshow.resize();
    }
    setTimeout(() => {
      TryMe();
    }, 1500);
    clearTimeout();
    // slideshow.once("lazyLoad", slideshow.resize());
    // if (slideshow.once("lazyLoad")) {
    //   slideshow.resize;
    // }
  })();

  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <body className={refTwo.body} onClick={() => props.setMenuOpen(false)}>
      <div className={refTwo["mini-navbar"]}>
        <div className={refTwo.container}>
          <p className={refTwo["back-btn"]}>
            <Link to="/references">BACK</Link>
          </p>
        </div>
      </div>

      <main className={refTwo.main}>
        <div className={refTwo.container}>
          <div className={`${refTwo["main-header"]}`}>
            <h1>REFERENCES</h1>
          </div>
        </div>

        {referenceLoading ? (
          <div className={refTwo.noNews}>
            <CircularProgress sx={{ color: "lightgray" }} />
          </div>
        ) : (
          <>
            <div className={refTwo.slideshowContainer}>
              <div id="showcase" className="showcase">
                {imageBlock ? (
                  imageBlock?.media?.map((item, i) => (
                    <div
                      key={i}
                      className={`${refTwo.carouselItem} carousel-cell`}
                      style={{
                        backgroundImage: `url(${
                          ImageKit({url:item?.url}) ||
                          placeholderImg
                        })`,
                      }}
                    ></div>
                  ))
                ) : (
                  <div
                    className={`${refTwo.carouselItem} carousel-cell`}
                    style={{
                      backgroundImage: `url(${
                      ImageKit({url:reference?.featured_image}) || placeholderImg
                      })`,
                    }}
                  ></div>
                )}
              </div>
            </div>

            <div className={refTwo.container}>
              <div className={refTwo.projectDetails}>
                <div className={refTwo.firstRow}>
                  <div className={refTwo.box}>
                    <h3>PROJECT</h3> <p>{reference?.title}</p>
                  </div>
                  <div className={refTwo.box}>
                    <h3>SECTOR</h3> <p>{portfolioName}</p>
                  </div>
                  <div className={refTwo.box}>
                    <h3>LOCATION</h3> <p>{reference?.location}</p>
                  </div>
                  <div className={refTwo.box}>
                    <h3>STATUS</h3> <p>{reference?.status}</p>
                  </div>
                </div>

                <h3>PROJECT SUMMARY</h3>
              </div>
              {textBlocks?.length > 0 ? (
                textBlocks?.map((block, i) => (
                  <p className={refTwo.content} key={i}>
                    {parse(block?.content)}
                  </p>
                ))
              ) : (
                <p className={refTwo.content}>
                  Project details currently unavailable
                </p>
              )}
            </div>
          </>
        )}
      </main>
      <Footer style={refTwo} />
    </body>
  );
}

export default Ref;
