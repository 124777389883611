import React, { useEffect, useMemo, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import "./carousel.css";
import "./flickity.css";
import { Link } from "react-router-dom";
import { useGetCultureQuery } from "../../culture/companyApiSlice";
import parse from "html-react-parser";

import ImageKit from "../../../helpers/ImageKit";
import Flickity from "react-flickity-component";
import asNavFor from "flickity-as-nav-for";
import flickityFade from "flickity-fade";
import flickitySync from "flickity-sync";
import { CircularProgress } from "@mui/material";
import LazyLoad from "react-lazyload";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
function Carousel() {
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  const { data, isLoading: cultureLoading } = useGetCultureQuery();
  const culture = useMemo(() => data?.culture?.blocks, [data?.culture?.blocks]);
  const descriptions = useMemo(
    () => culture?.filter((block) => block?.type === "text"),
    [culture]
  );
  const images = useMemo(
    () => culture?.filter((block) => block?.type === "image"),
    [culture]
  );
  const slideItemCount = useMemo(() => images?.length, [images]);

  const timerCount = 3000;
  const [intervalId, setIntervalId] = useState(null);
  const [flickityRef, setFlickityRef] = useState(null);

  const flickityOptions = {
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    lazyLoad: true,
    autoPlay: 4000,
    freeScroll: true,
    freeScrollFriction: 0.01,
    selectedAttraction: 0.015,
    friction: 0.25,
    // fade: true,
    // imagesLoaded: true,
  };
  const descriptionFlickityOptions = {
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    fade: true,
    draggable: true,
    lazyLoad: true,
    sync: ".main-carousel-container",
    freeScroll: true,
    freeScrollFriction: 0.01,
    selectedAttraction: 0.015,
    friction: 0.25,
  };
  const navOptions = {
    pageDots: false,
    prevNextButtons: false,
    // wrapAround: true,
    asNavFor: ".main-carousel-container",
    contain: true,
    cellAlign: "right",
    freeScroll: true,
    freeScrollFriction: 0.01,
    selectedAttraction: 0.015,
    friction: 0.25,
  };

  // const doSlide = (index)=>{
  //   const id = setInterval(() => {
  //     flickityRef?.next();
  //   }, timerCount);
  //   setIntervalId(id);

  //   return () => clearInterval(intervalId);
  // }

  // useEffect(() => {
  //   doSlide();
  // }, [timerCount, flickityRef]);

  // const resetInterval = ()=>{
  //   clearInterval(intervalId);
  //   doSlide();
  // }

  function removeHTMLTags(html) {
    var temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || "";
  }

  return (
    <>
      <section className="firstCarousel section">
        <div className="myContainer">
          {cultureLoading ? (
            <div className="loadingContainer">
              <CircularProgress sx={{ color: "lightgray" }} />
            </div>
          ) : (
            <div className="firstCarouselContainer">
              <div className="description-block-container">
                <div></div>
                <Flickity
                  className={"carousel"}
                  elementType={"div"}
                  options={descriptionFlickityOptions}
                  disableImagesLoaded={false}
                  // reloadOnUpdate
                  static
                >
                  {images.map((image, i) => {
                    return (
                      <div key={i}>
                        <div className="description-block">
                          <h1>{image.caption}</h1>
                          <p className="description">
                            {/* <ResponsiveEllipsis
                            className="paraDescription"
                            text={removeHTMLTags(descriptions[i].content)}
                            maxLine="3"
                            ellipsis="..."
                            trimRight
                            basedOn="words"
                          />{" "} */}
                            {parse(descriptions[i].content) || "lorem ipsum"}
                          </p>
                          <button className="myBtn">
                            <Link to="corporate-culture">
                              <p style={{ paddingTop: "4.3px" }}>LEARN MORE</p>
                              <FiChevronRight
                                color="#c4936"
                                className="btn_icon"
                              />
                            </Link>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </Flickity>
              </div>
              <Flickity
                className={"carousel main-carousel-container"}
                elementType={"div"}
                options={flickityOptions}
                // flickityRef={(flickityRef)=>{setFlickityRef(flickityRef)}}
                disableImagesLoaded={false}
                // reloadOnUpdate
                static
              >
                {images.map((image, i) => {
                  return (
                    <div
                      className="background-image-block"
                      style={{
                        backgroundImage: `url(${ImageKit({url:image.media[0].url})})`,
                        width: "100%",
                        backgroundSize: "cover",
                      }}
                    ></div>
                  );
                })}
              </Flickity>

              <Flickity
                id="carousel-nav"
                className="carousel-nav carousel"
                options={navOptions}
              >
                {images?.map((image, i) => (
                  <div
                    key={i}
                    className="carousel-cell"
                    // style={{width: 'auto'}}
                    style={{
                      width: `calc(100% / ${slideItemCount})`,
                    }}
                  >
                    <h6 style={{ textTransform: "uppercase" }}>
                      {image?.caption}
                    </h6>
                  </div>
                ))}
              </Flickity>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Carousel;
