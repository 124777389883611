import { apiSlice } from "../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Dashboard"],
});

const dashboardApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    addView: builder.mutation({
      query: (values) => ({
        url: "/dashboard",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Dashboard"],
    }),
  }),
});

export const { useAddViewMutation } = dashboardApiSlice;
