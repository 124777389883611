import { apiSlice } from "../../../app/apiSlice";

const searchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getResult: builder.query({
      query: (parameter) => `/search?term=${parameter}`,
    }),
  }),
});

export const { useGetResultQuery } = searchApiSlice;
