import React from "react";
import { useGetReferencesQuery } from "./referencesApiSlice";
import ref from "./reference.module.css";
import placeholderImg from "../../images/placeholder_img.png";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import ImageKit from "../../helpers/ImageKit";

function RefCategory(category) {
  const portfolio = category?.category;

  const { data: refData, isLoading } = useGetReferencesQuery();

  const refCategory = refData?.references?.filter(
    (item) => item?.portfolio_id === portfolio?.id
  );

  return (
    <>
      {isLoading ? (
        <div className={ref.noNews}>
          <CircularProgress sx={{ color: "lightgray" }} />
        </div>
      ) : refCategory?.length > 0 ? (
        <div className={ref.items}>
          {refCategory?.map((post) => (
            <div
              key={post.id}
              className={ref.item}
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
            >
              <Link
                to="reference"
                state={{ id: post.id }}
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className={ref.refBox}
                  style={{
                    backgroundImage: `url(${
                      ImageKit({url:post?.featured_image}) || placeholderImg
                    })`,
                  }}
                >
                  {isLoading && (
                    <CircularProgress
                      sx={{
                        float: "right",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    />
                  )}
                  <span>
                    <p>{post?.title || "Title"}</p>
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p className={ref.noNews}>
          <em>There are currently no projects under this category...</em>
        </p>
      )}
    </>
  );
}

export default RefCategory;
