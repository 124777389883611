import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ImageKit from "../../helpers/ImageKit";
import careers from "./careers.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useGetCareersQuery } from "./careerApiSlice";
import parse from "html-react-parser";
import { CircularProgress } from "@mui/material";
import Footer from "../../layout/Footer";

function Careers(props) {
  const { data: openings, isLoading: careersLoading } = useGetCareersQuery();

  const metaData = openings?.meta;


  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  return (
    <body className={careers.body} onClick={() => props.setMenuOpen(false)}>
      <main className={careers.main}>
        <section className={careers.sectionOne}>
          <div className={careers.container}>
            <div className={careers["main-header"]}>
              <h1>
                {metaData?.title}
              </h1>
            </div>
          </div>
          <div className={careers["banner-container"]}>
            <div className={careers["front-cover"]} style={{backgroundImage:`url(${ImageKit({url:metaData?.blocks?.find((data)=>data.type==='image')?.media[0]?.url}) || ""})`}}></div>
          </div>
        </section>
        <section
          className={`${careers.first} ${careers.section}`}
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
        >
          <div className={careers.container}>
            {parse(metaData?.blocks[1]?.content||"")}
          </div>
        </section>

        <section className={`${careers.second} ${careers.section}`}>
          <div className={careers.container}>

            {


              metaData && metaData?.blocks?.filter((data)=>data.type==='text').slice(1).map((data, index)=>(
                index%2 !== 0 ?
              <div className={careers.items}>
              <div
                className={careers.image}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
              >
                <img src={ImageKit({url:metaData?.blocks?.filter((data)=>data.type==='image')?.slice(1)[index]?.media[0]?.url}) || ""} className={careers.imageSize} alt="" />
              </div>
              <div
                className={careers.writings}
                data-aos="fade-in"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
              >
                <h3>{metaData?.blocks?.filter((data)=>data.type==='image')?.slice(1)[index]?.caption}</h3>
                {parse(data?.content || "")}
              </div>
            </div>
            :
            <div className={careers.items}>
              <div
                className={careers.writings}
                data-aos="fade-right"
                data-aos-offset="100"
                data-aos-easing="ease-in-sine"
              >
                <h3>{metaData?.blocks?.filter((data)=>data.type==='image').slice(1)[index]?.caption}</h3>
                {parse(data?.content || "")}
              </div>
              <div
                className={careers.image}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
              >
                <img src={ImageKit({url:metaData?.blocks?.filter((data)=>data.type==='image')?.slice(1)[index]?.media[0]?.url}) || ""} className={careers.imageSize} alt="" />
              </div>
            </div>
              ))


            }

          </div>
        </section>

        <section
          className={careers.opportunities}
          // data-aos="fade-up"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-sine"
        >
          <div className={careers.container}>
            <div className={careers.jobs}>
              <h1>Find your next opportunity</h1>
            </div>
            {openings ? (
              openings?.careers?.length > 0 ? (
                openings?.careers?.map((job) => (
                  <div
                    className={careers.vacancy}
                    key={job.id}
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                  >
                    <h3>
                      <Link state={{ data: job.id }} to="job-listings">
                        {job.position}
                      </Link>
                    </h3>
                    <div className={careers.descFlexbox}>
                      <div className={careers.jobInfo}>
                        <p className="jobDescription">
                          {parse(job.description)}
                        </p>
                      </div>

                      <div className={careers.right}>
                        <h5>SEGMENT</h5>
                        <h6>{`${job.segment?.name}` || "UNSPECIFIED"}</h6>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={careers.noVacancy}>
                  <p>There are no open positions. Please, check back later</p>
                </div>
              )
            ) : (
              <div className={careers.loading}>
                {" "}
                <CircularProgress sx={{ color: "lightgray" }} />
              </div>
            )}
          </div>
        </section>
      </main>
      <Footer style={careers}/>
    </body>
  );
}

export default Careers;
