import { Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GoSearch } from "react-icons/go";
import AnimCursor from "../../customComponents/cursor/animated-cursor/AnimCursor";
import styles from "./search.module.css";
import { useGetResultQuery } from "./searchApiSlice";
import SearchInput from "./SearchInput";
import SearchResult from "./SearchResult";

function Search(props) {
  const [searchParam, setSearchParam] = useState("");

  const { data: searchResult, isLoading: isLoadingSearch } =
    useGetResultQuery(searchParam);

  return (
    <div onClick={() => props.setMenuOpen(false)}>
      <SearchInput value={searchParam} setValue={setSearchParam} />
      <SearchResult result={searchResult?.search || []} />
    </div>
  );
}

export default Search;
