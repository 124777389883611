import React from "react";
import { useState } from "react";
import styles from "./search.module.css";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../layout/Footer";

function Items({ currentItems }) {
  const navigate = useNavigate();
  function goToPage(type, id) {
    let route;
    switch (type) {
      case "news":
        route = "news/news-post/"+id;
        break;
      case "segment":
        route = "segments";
        break;
      case "portfolio":
        route = "business-portfolio";
        break;
      case "reference":
        route = "references/reference/"+id;
        break;
      case "career":
        route = "careers/job-listings";
        break;

      default:
        route = "search";
        break;
    }
    // console.log(`${route}/${id}`);
    navigate(`/${route}`, {
      state: {
        id: id,
      },
    });
  }

  return (
    <>
      {currentItems && currentItems.length ? (
        currentItems.map((item, i) => (
          <div
            key={i}
            className={styles.resultItem}
            onClick={() => goToPage(item.type, item.id)}
          >
            <h3 className={styles.title}>{item.title}</h3>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
            <p className={styles.subtext}>/{item.type}</p>
            <hr />
          </div>
        ))
      ) : (
        <div className={styles.noResultContainer}>
          <p className={styles.noResult}>No Results Found</p>
        </div>
      )}
    </>
  );
}

function PaginatedItems({ itemsPerPage, items }) {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel=""
        renderOnZeroPageCount={null}
        containerClassName={styles.paginationContainer}
        pageClassName={styles.page}
        activeClassName={styles.activePage}
        nextClassName={styles.nextButton}
      />
    </>
  );
}

function SearchResult({ result }) {
  return (
    <div className={styles.body} id="search-result">
      <main className={styles.main}>
        <div className={styles.container}>
          <PaginatedItems items={result?.result} itemsPerPage={4} />
        </div>
      </main>
      <Footer style={styles}/>
    </div>
  );
}

export default SearchResult;
