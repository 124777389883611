import React, { useEffect, useState } from "react";
import placeholderImg from "../../images/placeholder_img.png";
import { Link, Navigate } from "react-router-dom";
import news from "./news.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useGetNewsCategoriesQuery, useGetNewsQuery } from "./newsApiSlice";
import { CircularProgress } from "@mui/material";
import NewsCategory from "./NewsCategory";
import ImageKit from "../../helpers/ImageKit";
import Footer from "../../layout/Footer";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

function News(props) {
  const { data: newsitems, isLoading: newsLoading } = useGetNewsQuery();
  const newsPosts = newsitems?.news;
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  const { data, isLoading } = useGetNewsCategoriesQuery();

  //   Animate on scroll
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  const [activeTab, setActiveTab] = useState("All");

  console.log(activeTab, "newsCat");

  return (
    <body className={news.body} onClick={() => props.setMenuOpen(false)}>
      <div className={news["mini-navbar"]}>
        <div className={news.container}>
          <ul>
            <li onClick={() => setActiveTab("All")}>
              <a className={activeTab === "All" && news.active}>ALL</a>
            </li>
            {data?.categories?.map((category, i) => (
              <li key={i} onClick={() => setActiveTab(`${category?.name}`)}>
                <a
                  className={
                    activeTab === `${category?.name}` ? news.active : ""
                  }
                >
                  {category?.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <main className={news.main}>
        <div className={news.container}>
          <div className={news["main-header"]}>
            <h1>NEWS</h1>
          </div>
        </div>

        <div className={news.container}>
          {newsLoading ? (
            <div className={news.noNews}>
              <CircularProgress sx={{ color: "rgba(0,0,0,0.4)" }} />
            </div>
          ) : data?.categories?.find(
              (category) => category?.name === activeTab
            ) ? (
            <NewsCategory
              className={news.cat}
              category={(() => {
                const one = data?.categories?.find(
                  (category) => category?.name === activeTab
                );
                return one;
              })()}
            />
          ) : (
            <div className={news.items}>
              {newsPosts?.map((post) => (
                <Link
                  to={`news-post/${post.id}`}
                  state={{ id: post.id }}
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    key={post.id}
                    className={news.item}
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-easing="ease-in-sine"
                  >
                    <img
                      className={news.image}
                      src={
                        ImageKit({url:post?.featured_image}) ||
                        placeholderImg
                      }
                      alt={post?.title}
                    />
                    <p style={{ height: "70px  " }}>
                      <ResponsiveEllipsis
                        // className="paraDescription"
                        text={post?.title}
                        maxLine="2"
                        ellipsis=""
                        trimRight
                        basedOn="letters"
                      />
                      {/* {post?.title || "Headline"} */}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </main>
      <Footer style={news} />
    </body>
  );
}

export default News;
